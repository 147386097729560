.qualification__tabs {
  display: flex;
  justify-content: center;
  gap: 1.25rem;
  margin-bottom: 2rem;
}

.tab__font{
    font-size: large;
}

.qualification__button {
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition);
}

.qualification__button:hover {
  color: var(--color-primary-variant);
}

.qualification__icon {
  font-size: 1.8rem;
  margin-right: 0.55rem;
}

.qualification__data {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.25rem;
}

.qualification__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 0.8rem;
  padding: 1rem;
  transition: var(--transition);
}

.qualification__card:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.qualification__title {
  font-weight: 500;
}

.qualification__subtitle {
  display: inline-block;
  /* font-size: 0.813rem; */
  margin-bottom: 1rem;
}

.qualification__calendar {
  font-size: 0.813rem;
  color: var(--color-light);
}

.qualification__rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: var(--color-primary);
  border-radius: 50%;
}

.qualification__line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--color-primary);
  transform: translate(6px, -7px);
}

.calendar__icon{
    color: var(--color-primary);
}

.qualification__active {
  display: block;
}

.qualification__inactive {
  display: none;
}

.qualification__button.qualification__active {
  color: var(--color-primary);
}


@media screen and (max-width: 1024px) {
  .qualification__sections{
    display: grid;
    grid-template-columns: .7fr;
    justify-content: center;
}
}

@media screen and (max-width: 600px) {
  .qualification__data{
    grid-template-columns: 1fr max-content 1fr;
}

.qualification__tabs{
    justify-content: center;
}
.qualification__button{
    margin: 0 var(--mb-1);
}
.qualification__sections{
    grid-template-columns: 1fr;
}
}