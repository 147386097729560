header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

/* styling buttons */
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.25rem;
    justify-content: center;
}

/* styling for social media platforms */
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.9rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* Styling the image */
.nividImage{
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 3rem 1.5rem 1.5rem 2.5rem;
}

/* scroll down link to contact */
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* media queries to make the website responsive */
/* -------for medium devices------------ */
@media screen and (max-width: 1024px) {
    header{
        height: 68vh;
    }
}

/* -------for small devices------------ */
@media screen and (max-width: 600px) {
    header{
        height: 100vh;
    }

    .header__socials, .scroll__down{
        display: none;
    }
}